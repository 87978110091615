<template>
  <div id="app">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-xl-10 col-lg-12 col-md-9">
                  <div class="card o-hidden border-0 shadow-lg my-5">
                      <div class="card-body p-0">
                          <div class="row">
                              <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                              <div class="col-lg-6">
                                  <div class="p-5">
                                      <div class="text-center">
                                          <img src="./assets/sru-logo.png" alt="" height="150" class="mb-3">
                                          <h1 class="h3 text-gray-900 mb-4 font-weight-bold font-prompt">Forgot Password</h1>
                                      </div>
                                      <div class="text-center">
                                          <p class="font-prompt font-weight-bold">แจ้งลืมรหัสผ่าน <span class="text-danger">SRU Mail, SRU WIFI</span></p>
                                          <p class="font-prompt font-weight-bold text-muted">มหาวิทยาลัยราชภัฏสุราษฎร์ธานี</p>
                                      </div>
                                      <hr>
                                      <a href="/login" class="btn btn-primary btn-user btn-block font-prompt">ล็อกอินด้วย Username Password ระบบ REG</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import './assets/sb-admin-2.min.css';
@import './assets/custom.css';
body {
    background-color: #d9d9d9;
}
</style>
